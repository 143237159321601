import request from "@utils/request";

export function forgetPassword(query) {
    return request({
        url: "security/forget-password",
        method: "post",
        data: query
    });
}

export function resetPassword(query) {
    return request({
        url: "security/reset-password",
        method: "post",
        data: query
    });
}
