<template>
    <v-app>
        <v-card
            width="500"
            outlined
            class="mx-auto my-auto"
            :loading="btnLoading"
        >
            <v-container class="my-auto">
                <v-row>
                    <v-card-title>
                        <v-icon large left>mdi-sigma</v-icon>
                        <span class="title font-weight-light"
                            >Reset Password</span
                        >
                    </v-card-title>
                </v-row>

                <v-row>
                    <v-col lg="12" md="12" xs="12" cols="12" class="pb-0">
                        <v-form ref="form" autocomplete="chrome-off">
                            <v-text-field
                                label="new password"
                                name="new password"
                                solo
                                flat
                                prepend-inner-icon="mdi-lock"
                                :rules="newPasswordRules"
                                type="password"
                                v-model="newPassword"
                                required
                                outlined
                                dense
                            />
                            <v-text-field
                                single-line
                                label="repeat password"
                                name="repeat password"
                                solo
                                flat
                                prepend-inner-icon="mdi-lock"
                                :rules="repeatRules"
                                type="password"
                                v-model="repeatPassword"
                                required
                                outlined
                                dense
                            />
                        </v-form>
                    </v-col>
                </v-row>

                <v-row justify="center" class="pt-0 mt-0">
                    <v-col lg="12" md="12" xs="12" cols="12" align="center">
                        <v-btn
                            block
                            color="info"
                            :loading="btnLoading"
                            @click="handleReset"
                            :disabled="!valid"
                        >
                            SUBMIT
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-card-text class="mb-5 pb-0">
                        <p
                            class="text-center font-weight grey--text overline mb-0"
                        >
                            <a @click="redirectSignin">Back to Sign In</a>
                        </p>
                    </v-card-text>
                </v-row>
            </v-container>
        </v-card>
    </v-app>
</template>
<script>
import { resetPassword } from "@services/account/password";
import { snackbar } from "@components/mixins/snackbar";
import axios from "axios";

export default {
    name: "ResetPassword",
    mixins: [snackbar],
    props: {},
    watch: {
        newPassword() {
            if (this.$refs.form?.validate()) {
                this.valid = true;
            } else {
                this.valid = false;
            }
        },
        repeatPassword() {
            if (this.$refs.form?.validate()) {
                this.valid = true;
            } else {
                this.valid = false;
            }
        },
    },
    data() {
        return {
            token: null,
            valid: false,
            newPassword: "",
            repeatPassword: "",
            btnLoading: false,
            requestStatus: false,
            newPasswordRules: [
                (v) => !!v || "Password is required",
                (v) => v.length >= 8 || "Password must contain 8 characters",
            ],
            repeatRules: [
                (v) => !!v || "Password is required",
                (v) => v.length >= 8 || "Password must contain 8 characters",
                (v) => v === this.newPassword || "Passwords do not match",
            ],
        };
    },
    beforeRouteEnter(to, _1, next) {
        const token = to.query?.token;
        if (!!token) {
            next((vm) => {
                vm.token = token;
            });
        } else {
            next((vm) => {
                vm.$router.push({ name: "Signin" });
            });
        }
    },
    methods: {
        /**
         * Reset password post method
         * @return {[type]} [description]
         */
        async handleReset() {
            const params = {
                password: this.newPassword,
            };
            this.btnLoading = true;

            let APIURL =
                document.querySelector("body").getAttribute("apiurl") ||
                process.env.VUE_APP_ENDPOINT;
            APIURL = APIURL + "/api/v1/security/reset-password";

            try {
                // let broker = null;
                // let encryptedBrokerInfo = "UAT";
                // if (window.location.hostname.includes("pk-")) {
                //     broker = window.location.hostname
                //         .replace("pk-", "")
                //         .replace(".sigmarisk.com.au", "")
                //         .toLocaleUpperCase();
                // } else {
                //     broker = process.env.VUE_APP_SOCKET_API.replace(
                //         "https://socket-",
                //         ""
                //     )
                //         .replace(".sigmarisk.com.au", "")
                //         .toLocaleUpperCase();
                // }
                // // encrypt broker info with secret_key
                // const yearMonth = dayjs().format("YYYY-MM");
                // const secretKey = "super-secret";
                // const hash = crypto.createHash("sha256");
                // hash.update(broker + "-" + secretKey + "-" + yearMonth);

                // encryptedBrokerInfo = hash.digest("hex");

                const res = await axios.post(APIURL, params, {
                    headers: {
                        Authorization: "Bearer " + this.token,
                        // Broker: encryptedBrokerInfo,
                    },
                });
                if (res.status == 200) {
                    this.snackBarSuccess(res.data.msg, 1000);
                    setTimeout(() => {
                        this.redirectSignin();
                    }, 1000);
                }
                this.btnLoading = false;
            } catch (e) {
                this.snackBarDanger(e.response?.data?.msg || "Error");
                this.btnLoading = false;
            }
        },
        /**
         * Go back to signin page
         * @return {[type]} [description]
         */
        redirectSignin() {
            this.$router.push({ name: "Signin" });
        },
    },
};
</script>
